import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
// import { IoMdClose } from 'react-icons/io';


const CreateModel = ({
  isOpen,
  toggle,
  title,
  children,
  className,
  closeBtn,
  ...rest
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={className}
      centered={true}
    >
      <ModalHeader className="border-bottom-0 ">
        <div className="close-button" onClick={() => toggle(false)}>
          {/* <IoMdClose size={24} color="gray" className="close-icon" /> */}
          <i className="fas fa-times" style={{ fontSize: '24px', color: 'gray' }}></i>
        </div>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export { CreateModel };
