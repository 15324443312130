import React, { useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import bg from '../../assets/img/bg/breadcrumb-bg-smoke.jpg';
import FormField from '../../components/common/FormField';
import validate from 'validate.js';
import { api } from '../../services/api';
import { registerApi } from '../../services/apiVariables';
import { Toast } from '../../services/toast';
import FormInput from '../../components/common/FormInput';

const RegisterForm = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get('name');
  const contestId = searchParams.get('_id');
  const [errors, setErrors] = useState({});

  const initialFormValues = {
    childName: '',
    dateOfBirth: '',
    mobileNumber: '',
    schoolName: '',
  };
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const resetForm = () => {
    setFormValues({ ...initialFormValues });
  };
  const handleInputChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { registerFormApi } = registerApi;
    const validationResult = validate(formValues, validationRules);

    if (validationResult) {
      setErrors(validationResult);
    } else {
      try {
        const response = await api({
          ...registerFormApi,
          body: {
            ...formValues,
            contestId: contestId,
          },

        });
        if (response.status === 200) {
          const { token } = response.data;
          localStorage.setItem('token', token);
          Toast({
            type: 'success',
            message: 'Successfully Registered for contest. Wait for further Notice',
          });
          resetForm();
        } else {
          Toast({
            type: 'error',
            message: response?.error || 'Try again or call admin',
          });
        }
      } catch (error) {
        console.error('API Error:', error);
        Toast({
          type: 'error',
          message: error?.error || 'Try again or call admin',
        });
      }
    }
  };

  return (
    <>
      <section className='event-section bg-smoke space'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='contact-form-box'>
                <h2 className='contact-title'>Register for Summer Contest</h2>
                <div className='row'>
                  <div className='col-lg-6 col-12'>
                    <div className='contest_image_wrapper mb-4'>
                      <img
                        className='contestImage'
                        src={require('../../assets/img/summercontest.jpeg')} alt='contest'
                      />
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <form className='contact-form ' onSubmit={handleSubmit}>
                      <div className='row'>
                        <div className='form-group col-12 '>
                          <FormInput
                            type='text'
                            placeholder='Your Child Name'
                            icon='fa-user'
                            name='childName'
                            label='Child Name'
                            className=""
                            value={formValues.childName}
                            onChange={handleInputChange}
                            error={errors['childName']}
                          />
                        </div>
                        <div className='form-group col-12'>
                          <FormInput
                            type='date'
                            placeholder='choose date'
                            icon='fa-calendar'
                            label='Child Date Of Birth'
                            name='dateOfBirth'
                            value={formValues.dateOfBirth}
                            onChange={handleInputChange}
                            error={errors['dateOfBirth']}
                          />
                        </div>
                        <div className='form-group col-12'>
                          <FormInput
                            type='text'
                            placeholder='Your mobile number'
                            icon='fa-phone'
                            name='mobileNumber'
                            label='Mobile Number'
                            value={formValues.mobileNumber}
                            onChange={handleInputChange}
                            error={errors['mobileNumber']}
                          />
                        </div>

                        <div className='form-group col-12'>
                          <FormInput
                            name='schoolName'
                            type='text'
                            placeholder='Child school name'
                            icon='fa-pencil-alt'
                            label='School Name'
                            value={formValues.schoolName}
                            onChange={handleInputChange}
                            error={errors['schoolName']}
                          />
                        </div>

                        <div className='form-group col-12 d-flex justify-content-end'>
                          <button
                            type='submit'
                            id='submit-btn'
                            className='vs-btn wave-btn'
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterForm;
const validationRules = {
  childName: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  dateOfBirth: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  schoolName: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  mobileNumber: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
    format: {
      pattern: /^\d{10}$/,
      message:
        ' should be 10 digits long and contain only numbers',
    },
  },
};
