import React, { useState, useRef } from 'react';
import { Row, Col, Card, Form } from 'reactstrap';
import Dropzone from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import { Spinner } from 'reactstrap';

const FileUpload = (props) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleAcceptedFiles = async (files) => {
    setIsLoading(true);
    await props.handleFileUpload(files[0]);
    setIsLoading(false);
  };

  const handleUploadButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const renderPreview = () => {
    return (
      <Card
        className='shadow-none border dz-processing dz-image-preview dz-success dz-complete'
        key={'-file'}
        style={{ position: 'relative' }}
      >
        <div className='dropzone-image-viewer'>
          {props.type === 'video' && props.preview && (
            <video
              controls
              className='avatar-sm rounded bg-light'
              alt={'child video'}
              src={props.preview}
              style={{ width: '100%', height: '300px' }}
            />
          )}
          {props.type === 'image' && props.preview && (
            <img
              className='avatar-sm rounded bg-light'
              alt={'child pic'}
              src={props.preview}
              name={props.name}
              style={{ width: '100%', height: '300px', objectFit:'contain' }}
            />
          )}
          <input
            ref={fileInputRef}
            type='file'
            accept='.mp4, video/*, image/jpeg, image/png'
            style={{ display: 'none' }}
            onChange={(e) => handleAcceptedFiles(e.target.files)}
          />

          <div className='dz-message needsclick'>
            {isLoading ? (
              <Spinner className='upload-loader'>
                <p>Loading...</p>
              </Spinner>
            ) : (
              <div onClick={handleUploadButtonClick}>
                <i className='display-6 text-muted fas fa-cloud-upload-alt'></i>
                <p>{props.title}</p>
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col className='col-12'>
          <Form>
            <Dropzone
              onDrop={(acceptedFiles, rejectedFiles) => {
                if (rejectedFiles.length > 0) {
                  setError('File type not supported');
                } else {
                  handleAcceptedFiles(acceptedFiles);
                }
              }}
              accept='.mp4, video/*, image/jpeg, image/png'
            >
              {({ getRootProps, getInputProps }) => (
                <div className='dropzone'>
                  <div className='dropzone-previews' id='file-previews'>
                    {renderPreview()}

                    {/* <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div>
                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                      </div>
                      <p>{props.title}</p>
                    </div> */}
                  </div>
                </div>
              )}
            </Dropzone>
            <p className='note'>Note: Upload a {props.type} file with a maximum size of<span> {props.mb} mb.</span></p>
            {props.error && <p style={{ color: 'red' }}>{props.error}</p>}
          </Form>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FileUpload;
