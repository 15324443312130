import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastBar, Toaster } from 'react-hot-toast';

import './assets/scss/app.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <Toaster
        position="bottom-right"
        gutter={8}
        reverseOrder
        containerClassName="custom-toast"
        containerStyle={{ right: '40px', bottom: '50px' }}
      >
        {(t) => (
          <ToastBar toast={t} style={{ padding: 0, overflow: 'hidden' }}>
            {({ icon, message }) => {
              return (
                <div
                  className={`custom-toast-${t.type} d-flex align-items-center custom-toast-item`}
                >
                  {icon}
                 
                  {message}
                </div>
              );
            }}
          </ToastBar>
        )}
      </Toaster>
  </React.StrictMode>
);


reportWebVitals();
