import React, { useState } from 'react';
import FormField from '../common/FormField';
import { Toast } from '../../services/toast';
import validate from 'validate.js';
import { registerApi } from '../../services/apiVariables';
import { api } from '../../services/api';
import FormInput from '../common/FormInput';
import ReactSelect from '../common/ReactSelect';

const EnquiryForm = () => {

  const branchOptions = [
    { value: 'Sathuvachari Branch', label: 'Sathuvachari Branch' },
    { value: 'Gandhi Nagar Branch', label: 'Gandhi Nagar Branch' },
  ];

  const initialFormValues = {
    name: '',
    email: '',
    mobileNumber: '',
    branch: null,
    message: '',
  };
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const resetForm = () => {
    setFormValues({ ...initialFormValues });
  };
  const handleInputChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };
     
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { registerFormApi } = registerApi;
    const validationResult = validate(formValues, validationRules);

    if (validationResult) {
      setErrors(validationResult);
    } else {
      try {
        const response = await api({
          ...registerFormApi,
          body: {
            ...formValues,
          },
        });
        if (response.status === 201) {
          Toast({ type: 'success', message: 'Enquiry Submitted Successfully' });
          resetForm();
        } else {
        }
      } catch (error) {
        console.error('API Error:', error);
      }
    }
  };

  return (
    <div className='contact-form-box'>
      <h2 className='contact-title'>Enquire Now</h2>
      <p className='mb-35'>
        Children love to learn! As an exemplary early learning school, we
        facilitate age-appropriate education that sparks the imagination of
        every child.
      </p>
      <form className='contact-form ' onSubmit={handleSubmit}>
        <div className='row'>
          <div className='form-group col-12 col-lg-6'>
            <FormInput
              type='text'
              placeholder='Name'
              icon='fa-user'
              name='name'
              label='Name'
              value={formValues.name}
              onChange={handleInputChange}
              error={errors['name']}
            />
          </div>
          <div className='form-group col-12 col-lg-6'>
            <FormInput
              type='text'
              placeholder='Mobile Number'
              icon='fa-user'
              name='mobileNumber'
              label='Mobile Number'
              value={formValues.mobileNumber}
              onChange={handleInputChange}
              error={errors['mobileNumber']}
            />
          </div>
          <div className='form-group col-12'>
            <ReactSelect
              name='branch'
              label='Branch'
              placeholder='Select Branch'
              options={branchOptions}
              onChange={handleInputChange}
              value={formValues.branch}
              error= {errors?.branch}
            />
          </div>
          <div className='form-group col-12'>
            <FormInput
              type='textarea'
              placeholder='Message'
              icon='fa-user'
              name='message'
              label='Message'
              value={formValues.message}
              onChange={handleInputChange}
              error={errors['message']}
            />
          </div>
          <div className='form-group col-12'>
            <button type='submit' id='submit-btn' className='vs-btn wave-btn'>
              Send Now
            </button>
          </div>
          <div className='my-3'>
            <div className='error-message'></div>
            <div className='sent-message'>
              Thank you for contacting us , we will get back within 1 business
              working days
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EnquiryForm;
const validationRules = {
  name: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  message: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  branch: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  mobileNumber: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
    format: {
      pattern: /^\d{10}$/,
      message:
        'should be 10 digits long and contain only numbers',
    },
  },
};